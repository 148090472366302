const VI = {
  overview: "Toàn cảnh",
  areas: "Phân khu",
  media: "Tiện ích",
  hotlineCopied: "Đã sao chép hotline vào bộ nhớ tạm",
  commingSoon: "Chức năng đang trong quá trình phát triển!",
  photoCaption: "Ảnh chụp tiện ích dự án",
  photoDescription: "Mô tả tiện ích dự án...",
  siteTitle: "Toàn cảnh 360 dự án Anara Bình Tiên",
  siteDescription: "Khám phá dự án Anara Bình Tiên góc nhìn 360",
  fullscreenNotSupported: "Trình duyệt không hỗ trợ chế độ toàn màn hình",
  user_manual: {
    title_1: "Trên màn hình cảm ứng",
    action_1: "Phóng to/Thu nhỏ",
    action_2: "Di chuyển",
    title_2: "Thao tác trên chuột",
    action_3: "Nhấn và kéo chuột để di chuyển. ",
    action_4: "Cuộn chuột để phóng to, thu nhỏ",
    title_3: "Thao tác trên bàn phím",
    action_5: "Dùng phím mũi tên để di chuyển",
    title_4: "Thao tác trên màn hình",
    action_6: "Bấm để di chuyển các vị trí khác",
  },
  anchor_point: {
    v43: {
      name: "Toàn cảnh 2",
      numCount: "Đây là toàn cảnh 2",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v44: {
      name: "Toàn cảnh 1",
      numCount: "Đây là toàn cảnh 1",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v45: {
      name: "Toàn cảnh 3",
      numCount: "Đây là toàn cảnh 3",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v46: {
      name: "Phân Khu B",
      numCount: "Đây là Phân khu B",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v47: {
      name: "Nhà thể thao Lagoon",
      numCount: "Đây là Sport center",
      shortDes:
        "Sport center Lorem Ipsum is simply dummy text of the printing and",
    },
    v48: {
      name: "Câu lạc bộ biển Elite",
      numCount: "Đây là Clubhouse",
      shortDes:
        "Clubhouse Lorem Ipsum is simply dummy text of the printing and",
    },
    v49: {
      name: "Công viên Nhiệt đới",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v50: {
      name: "Phân Khu A",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v51: {
      name: "SOLARI by ANARA",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v52: {
      name: "Phân Khu C",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v53: {
      name: "Phân Khu D",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v54: {
      name: "Phân Khu E",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v55: {
      name: "Khách sạn Golf 5*",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v56: {
      name: "Khu biệt thự sườn đồi",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v57: {
      name: "Swiss-Belvillas Solavita",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v58: {
      name: "Khu Chăm Sóc Sức Khỏe",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v59: {
      name: "Khu dịch vụ biển",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v60: {
      name: "Nhà Hàng Hải Đăng",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v61: {
      name: "Biệt Thự Mũi Cà Tiên",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v62: {
      name: "Khách sạn 6*",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v63: {
      name: "Khách sạn cửa ngõ 5*",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v64: {
      name: "Phân khu G",
      divisionName: "Sắp ra mắt",
      numCount: "Đây là Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
  },
  mediaPhoto: {
    overview1: {
      caption: "Toàn cảnh 1",
    },
    overview2: {
      caption: "Toàn cảnh 2",
    },
    overview3: {
      caption: "Toàn cảnh 3",
    },
    overview4: {
      caption: "Toàn cảnh 4",
    },
    area1: {
      caption: "Clubhouse",
    },
    area2: {
      caption: "Garden",
    },
    area3: {
      caption: "Sportcenter",
    },
    area4: {
      caption: "B7 Garden",
    },
    area5: {
      caption: "Clubhouse",
    },
    area6: {
      caption: "Stadium",
    },
    zone1: {
      caption: "Zone B1",
    },
    zone2: {
      caption: "Zone B2",
    },
    zone3: {
      caption: "Zone B3",
    },
    zoneB: {
      caption: 'Phân Khu B',
    },
  },
};

export default VI;
