import MainViewPano from '../../components/3d/MainViewPano';
import './index.scss';
//import mapCfg from '../../json/map.json'

const Home = () => {
  return (
    <>
      <MainViewPano />
    </>
  );
}

export default Home;