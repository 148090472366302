import './App.css';
import './styles/base/_font.scss';
import './styles/base/_base.scss';
import './styles/global.scss';
import './styles/scrollbar.scss';
import Home from './pages/Home';
import Layout from './components/Layout/Layout';
import { AppContextProvider } from './shared/contexts/AppContext';
import './i18n';

function App() {
  return (
    <AppContextProvider>
      <div className="App">
        <Layout>
          <Home />
        </Layout>
      </div>
    </AppContextProvider>
  );
}

export default App;
