const Icon1 = () => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="elements">
      <path
        id="Layer"
        d="M14.3983 24.5006L18.8438 28.3115L24.3953 7.02766C24.8067 5.45027 26.2313 4.34961 27.8611 4.34961C30.1715 4.34961 31.8775 6.50487 31.3473 8.7539L28.8453 19.3684L32.3603 19.9304C36.3782 20.5331 38.3873 20.8344 39.8023 21.6825C42.1398 23.0829 43.8332 25.1861 43.8332 28.2565C43.8332 30.3952 43.3046 31.83 42.0198 35.6849L38.6248 43.9385L20.9165 43.9388L9.22778 29.5373C8.15257 28.214 8.1454 26.3198 9.21055 24.9882C10.4908 23.3877 12.8423 23.1666 14.3983 24.5006Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        id="Layer_2"
        d="M8.65526 12.4443L3.2085 17.8774M2.19528 12.6638L2.15869 18.9246H8.44386M12.3216 8.77582L17.7918 3.32748M12.5584 2.2793L18.8442 2.2793L18.8437 8.50932"
        stroke="white"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const Icon2 = () => (
  <svg
    width="42"
    height="46"
    viewBox="0 0 42 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="elements">
      <path
        id="Layer"
        d="M16.8024 28.2083L15.8262 29.3472L18.3024 31.4697V28.2083H16.8024ZM12.3568 24.3978L13.3329 23.2589H13.3329L12.3568 24.3978ZM7.18627 29.434L8.43411 28.6016L8.39494 28.5429L8.35044 28.4881L7.18627 29.434ZM16.7917 43.8333L15.5438 44.6657C15.8221 45.0828 16.2903 45.3333 16.7917 45.3333V43.8333ZM24.094 19.8283H22.594V21.3283H24.094V19.8283ZM7.16904 24.8854L5.99773 23.9484L5.99773 23.9484L7.16904 24.8854ZM17.7786 27.0694L13.3329 23.2589L11.3806 25.5367L15.8262 29.3472L17.7786 27.0694ZM5.93843 30.2664L15.5438 44.6657L18.0395 43.0009L8.43411 28.6016L5.93843 30.2664ZM18.3024 28.2083V7.89583H15.3024V28.2083H18.3024ZM22.594 7.89583V19.8283H25.594V7.89583H22.594ZM24.094 21.3283H33.4583V18.3283H24.094V21.3283ZM33.4583 42.3333H16.7917V45.3333H33.4583V42.3333ZM20.4482 5.75C21.6333 5.75 22.594 6.71072 22.594 7.89583H25.594C25.594 5.05387 23.2902 2.75 20.4482 2.75V5.75ZM18.3024 7.89583C18.3024 6.71072 19.2631 5.75 20.4482 5.75V2.75C17.6062 2.75 15.3024 5.05387 15.3024 7.89583H18.3024ZM5.99773 23.9484C4.49167 25.8309 4.50182 28.5088 6.0221 30.3799L8.35044 28.4881C7.72033 27.7126 7.71612 26.6027 8.34034 25.8224L5.99773 23.9484ZM13.3329 23.2589C11.1328 21.3731 7.80792 21.6856 5.99773 23.9484L8.34034 25.8225C9.09061 24.8846 10.4687 24.7551 11.3806 25.5367L13.3329 23.2589ZM41.2083 37.5833V26.0783H38.2083V37.5833H41.2083ZM33.4583 45.3333C37.7385 45.3333 41.2083 41.8635 41.2083 37.5833H38.2083C38.2083 40.2067 36.0817 42.3333 33.4583 42.3333V45.3333ZM33.4583 21.3283C36.0817 21.3283 38.2083 23.4549 38.2083 26.0783H41.2083C41.2083 21.7981 37.7385 18.3283 33.4583 18.3283V21.3283Z"
        fill="white"
      />
      <path
        id="Layer_2"
        d="M28.25 6.33333L37.358 6.33333M34.5 10.5L38.6667 6.33333C38.6667 6.33333 35.5535 2.98588 34.5 2.16667"
        stroke="white"
        strokeWidth="3"
      />
      <path
        id="Layer_3"
        d="M12.625 6.33268L3.33331 6.33268M6.375 2.16602L2.20833 6.33268L6.375 10.4993"
        stroke="white"
        strokeWidth="3"
      />
    </g>
  </svg>
);

const Icon3 = () => (
    <svg width="41" height="46" viewBox="0 0 41 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" fillRule="evenodd" clipRule="evenodd" d="M5.85983 0.603516L7.6955 3.72852L4.10282 5.83891L2.26715 2.71391L5.85983 0.603516ZM0.185547 7.97092L4.35221 6.92925L5.36278 10.9715L1.19611 12.0132L0.185547 7.97092Z" fill="white"/>
<path id="Layer_2" fillRule="evenodd" clipRule="evenodd" d="M9.77423 7.55465C12.4667 3.18389 16.9571 0.603516 23.6249 0.603516C30.2928 0.603516 34.7832 3.1839 37.4757 7.55465C40.0546 11.7411 40.8125 17.3027 40.8125 22.9993C40.8125 28.696 40.0546 34.2576 37.4757 38.444C34.7832 42.8148 30.2928 45.3952 23.6249 45.3952C16.9571 45.3952 12.4667 42.8148 9.77423 38.444C7.19533 34.2576 6.4375 28.696 6.4375 22.9993C6.4375 17.3027 7.19533 11.7411 9.77423 7.55465ZM13.2089 9.70074C11.4933 12.4858 10.6963 16.3466 10.5187 20.9158H21.5397V17.7908H20.498C19.6351 17.7908 18.9355 17.0912 18.9355 16.2283V9.97828C18.9355 9.11534 19.6351 8.41578 20.498 8.41578H21.5397V4.78239C17.3471 5.23557 14.8157 7.09235 13.2089 9.70074ZM36.7273 20.9158C36.5497 16.3466 35.7527 12.4859 34.037 9.70077C32.4302 7.0924 29.8988 5.23561 25.7063 4.7824V8.41578H26.748C27.6109 8.41578 28.3105 9.11534 28.3105 9.97828V16.2283C28.3105 17.0912 27.6109 17.7908 26.748 17.7908H25.7063V20.9158H36.7273Z" fill="white"/>
</g>
</svg>

)

 const Icon4= ()=>(
    <svg width="35" height="46" viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" fillRule="evenodd" clipRule="evenodd" d="M17.4999 0.603516C15.4877 0.603516 13.5185 0.772329 11.6111 1.00732C5.77922 1.72586 1.27215 6.56191 0.818663 12.274C0.543525 15.7392 0.3125 19.3288 0.3125 22.9993C0.3125 26.6698 0.543525 30.2595 0.818663 33.7248C1.27216 39.4368 5.77944 44.2727 11.6112 44.9915C13.5184 45.2263 15.4877 45.3952 17.4999 45.3952C19.5121 45.3952 21.4816 45.2262 23.3888 44.9914C29.2208 44.2727 33.7275 39.4368 34.1812 33.7248C34.4564 30.2595 34.6875 26.6699 34.6875 22.9993C34.6875 19.3288 34.4564 15.7392 34.1812 12.274C33.7276 6.56196 29.2208 1.72586 23.3888 1.00732C21.4814 0.772329 19.5121 0.603516 17.4999 0.603516ZM23.6839 13.4449L17.4995 8.4974L11.3151 13.4449L13.2673 15.8851L15.937 13.7493V21.8307L13.2673 19.6949L11.3151 22.1351L17.4995 27.0827L23.6839 22.1351L21.7318 19.6949L19.062 21.8307V13.7493L21.7318 15.8851L23.6839 13.4449Z" fill="white"/>
</g>
</svg>

 )


 const Icon5= ()=>(
    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" d="M20.5008 29.3327V13.5869M26.75 18.916L20.5008 12.666L14.25 18.916" stroke="white" strokeWidth="3"/>
<path id="Layer_2" d="M39.25 2.25L39.25 39.75L1.75 39.75L1.75 2.25L39.25 2.25Z" stroke="white" strokeWidth="3" strokeLinejoin="round"/>
</g>
</svg>

 )

 const Icon6= ()=>(
    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" d="M28.8332 20.9992L13.0873 20.9992M18.4165 14.75L12.1665 20.9992L18.4165 27.25" stroke="white" strokeWidth="3"/>
<path id="Layer_2" d="M1.75 2.25L39.25 2.25L39.25 39.75L1.75 39.75L1.75 2.25Z" stroke="white" strokeWidth="3" strokeLinejoin="round"/>
</g>
</svg>
 )


 const Icon7= ()=>(
    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" d="M20.4992 12.6673L20.4992 28.4131M14.25 23.084L20.4992 29.334L26.75 23.084" stroke="white" strokeWidth="3"/>
<path id="Layer_2" d="M1.75 39.75L1.75 2.25L39.25 2.25L39.25 39.75L1.75 39.75Z" stroke="white" strokeWidth="3" strokeLinejoin="round"/>
</g>
</svg>
 )
 
 const Icon8= ()=>(
    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" d="M12.1668 21.0008L27.9127 21.0008M22.5835 27.25L28.8335 21.0008L22.5835 14.75" stroke="white" strokeWidth="3"/>
<path id="Layer_2" d="M39.25 39.75L1.75 39.75L1.75 2.25L39.25 2.25L39.25 39.75Z" stroke="white" strokeWidth="3" strokeLinejoin="round"/>
</g>
</svg>

 )

 const Icon9= (props)=>(
    <svg {...props} width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="elements">
<path id="Layer" d="M10.9784 11.4792C11.3478 11.1097 11.8774 10.9494 12.3897 11.0519L43.6393 17.3017C44.2044 17.4147 44.6614 17.8296 44.8284 18.3811C44.9953 18.9327 44.8452 19.5313 44.4377 19.9388L36.1678 28.2081L44.4381 36.4784C45.0483 37.0885 45.0483 38.0777 44.4383 38.6879L38.1892 44.9387C37.8962 45.2318 37.4988 45.3965 37.0843 45.3965C36.6699 45.3965 36.2724 45.2319 35.9794 44.9389L27.7084 36.6679L19.4381 44.9377C19.0306 45.3451 18.432 45.4952 17.8805 45.3282C17.329 45.1613 16.9141 44.7043 16.8011 44.1392L10.5511 12.8905C10.4487 12.3782 10.609 11.8486 10.9784 11.4792Z" fill="white"/>
<path id="Layer_2" fillRule="evenodd" clipRule="evenodd" d="M16.8325 0.603516V4.77018H12.6658V0.603516H16.8325ZM5.74381 3.29704L8.86881 6.42204L5.92253 9.36832L2.79753 6.24332L5.74381 3.29704ZM20.5059 6.42204L23.6309 3.29704L26.5771 6.24332L23.4521 9.36832L20.5059 6.42204ZM0.104004 13.1655H4.27067V17.3322H0.104004V13.1655ZM2.79753 24.1304L5.92253 21.0054L8.86881 23.9517L5.74381 27.0767L2.79753 24.1304Z" fill="white"/>
</g>
</svg>
 )

 export const MyIcons = {Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9}