import { useContext, useEffect } from "react";
import AppContext from "../shared/contexts/AppContext";
import { t } from 'i18next';

const Metadata = () => {
  const { isEnglish } = useContext(AppContext);

  useEffect(() => {
    document.title = t('siteTitle');
  }, []);

  return <>
    {/* <meta name="fb:app_id" content="" /> */}
    <meta property="og:locale" content={isEnglish ? 'en_US' : 'vi_VN'} />
    <meta property="og:title" content={t('siteTitle')} />
    <meta property="og:description" content={t('siteDescription')} />
    <meta name="description" content={t('siteDescription')} />
  </>
}

export default Metadata;