import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AnchorPointData from "./json/anchor_point.json";
import AnchorNode from "./components/AnchorPoint";
import { AnchorNodeFly } from "./components/AnchorPoint/AnchorNode";
import { showPin } from "./shared/utils/common-utils";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

window.changenodepano = () => {
  let e = document.getElementsByClassName("ggskin ggskin_hotspot");
  for (let i = 0; i < e.length; i++) {
    let element = e[i];
    let hotspotId = element.getHotspotId();
    if (document.getElementById(hotspotId)) continue;

    const el = ReactDOM.createRoot(element);
    const data = AnchorPointData[hotspotId];
    if (!data) continue;

    let prop = {
      id: hotspotId,
      data: {
        src: data.img,
        panorama: data.panorama,
        name: ["anchor_point", hotspotId, "name"].join("."),

        numCount: ["anchor_point", hotspotId, "numCount"].join("."),
        shortDes: ["anchor_point", hotspotId, "shortDes"].join("."),
        photo2DUrl: data.photo2DUrl,
        view360: data.view360
      },
    };
    if (data.divisionName) {
      prop.data["divisionName"] = ["anchor_point", hotspotId, "divisionName"].join(".");
    }

    if (element.getHotspotType() === "anchor_fly") {
      el.render(<AnchorNodeFly {...prop} />);
    } else {
      el.render(<AnchorNode {...prop} />);
    }
  }
  showPin(window.viewPin);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
