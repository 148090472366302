import { useState, useEffect } from 'react';

const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(checkIfFullscreen());
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    // Clean up event listeners when the component is unmounted
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  const checkIfFullscreen = () => {
    return !!document.fullscreenElement ||
    !!document.webkitFullscreenElement ||
    !!document.mozFullScreenElement ||
    !!document.msFullscreenElement;
  }

  useEffect(() => {
    try {
      if (isFullscreen) {
        if (!checkIfFullscreen()) {
          document.documentElement.requestFullscreen();
        }
      } else {
        if (checkIfFullscreen()) {
          document.exitFullscreen();
        }
      }
    } catch (error) {
      alert(`${error.message} - ${error.stack}`);
    }
  }, [isFullscreen]);

  return { isFullscreen, setIsFullscreen };
}

export default useFullscreen;