import "./UserManual.scss";
import { IoCloseOutline } from "react-icons/io5";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import { useContext, useEffect, useState } from "react";
import { MyIcons } from "./Icons";
import { Trans } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import { LOGO_TEXT_URL } from "../../shared/constants/const";
import { FaSpinner } from "react-icons/fa";
import AppContext from "../../shared/contexts/AppContext";

const UserManual = () => {
  const {manualOpen, setManualOpen} = useContext(AppContext);
  const [coverOpen, setCoverOpen] = useState(true);
  const handleClose = () => {
    if (coverOpen) {
      return;
    }
    setManualOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Check if pano init done
      if (window.panoLoadDone) {
        clearInterval(interval);
        setCoverOpen(false);
      }
    }, 200);
  }, []);

  const border = "rgba(218, 220, 225, 0.50) 2px solid";
  return (
    <>
      {coverOpen && <div className="fixed top-[100px] md:top-[30px] left-1/2 -translate-x-1/2 z-[11]">
        <img src={LOGO_TEXT_URL} width={200} />
      </div>}
      <div className={`fixed top-0 left-0 w-full bg-[#931640] z-[10] transition-all duration-500 ${coverOpen ? "h-1/2" : 'h-0'}`}></div>
      <div className={`fixed bottom-0 left-0 w-full bg-[#931640] z-[10] transition-all duration-500 ${coverOpen ? "h-1/2" : 'h-0'}`}></div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={manualOpen}
        onClick={handleClose}
        style={{ background: coverOpen ? 'transparent': "rgba(0, 0, 0, 0.5)" }}
      >
        <BrowserView>
          <div className="user-manual">
            <div className="row">
              <div className="column" style={{ borderRight: border }}>
                <h3>
                  <Trans i18nKey="user_manual.title_1"></Trans>
                </h3>
                <div className="row">
                  <div className="column cell">
                    <MyIcons.Icon1 />
                    <p>
                      <Trans i18nKey="user_manual.action_1"></Trans>
                    </p>
                  </div>
                  <div className="column cell">
                    <MyIcons.Icon2 />
                    <p>
                      <Trans i18nKey="user_manual.action_2"></Trans>
                    </p>
                  </div>
                </div>
              </div>
              <div className="column">
                <h3>
                  <Trans i18nKey="user_manual.title_2"></Trans>
                </h3>
                <div className="row">
                  <div className="column  cell">
                    <MyIcons.Icon3 />
                    <p>
                      <Trans i18nKey="user_manual.action_3"></Trans>
                    </p>
                  </div>
                  <div className="column  cell">
                    <MyIcons.Icon4 />
                    <p>
                      <Trans i18nKey="user_manual.action_4"></Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="column"
                style={{ paddingTop: 30, borderRight: border, borderTop: border }}
              >
                <div className="row">
                  <h3>
                    <Trans i18nKey="user_manual.title_3"></Trans>
                  </h3>
                  <div
                    className="column full"
                    style={{ padding: 0, paddingTop: 10, height: "auto" }}
                  >
                    <MyIcons.Icon5 style={{ width: "100%" }} />
                  </div>
                  <div className="column full fit">
                    <MyIcons.Icon6 />
                    <MyIcons.Icon7 />
                    <MyIcons.Icon8 />
                  </div>
                  <p style={{ paddingTop: 110, textAlign: "center" }}>
                    <Trans i18nKey="user_manual.action_5"></Trans>
                  </p>
                </div>
              </div>
              <div className="column" style={{ paddingTop: 30, borderTop: border }}>
                <h3>
                  <Trans i18nKey="user_manual.title_4"></Trans>
                </h3>
                <div
                  style={{
                    width: "100%",
                    height: 96,
                  }}
                >
                  <MyIcons.Icon9
                    style={{
                      margin: "auto",
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
                <p style={{ paddingTop: 14, textAlign: "center" }}>
                  <Trans i18nKey="user_manual.action_6"></Trans>
                </p>
              </div>
            </div>
            <Button
              sx={{
                borderRadius: "50%",
                minWidth: 0,
                padding: "6px",
                position: "absolute",
              }}
              style={{
                background: "white",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={handleClose}
              disabled={coverOpen}
            >
              { !coverOpen && <IoCloseOutline className="icon_close" size={25} color="black" /> }
              { coverOpen && <FaSpinner className="animate-spin" size={25}/>}
            </Button>
          </div>
        </BrowserView>
        <MobileView>
          <UserManualMobile handleClose={handleClose} coverOpen={coverOpen}/>
        </MobileView>
      </Backdrop>
    </>
  );
};

const UserManualMobile = ({ handleClose, coverOpen }) => {
  return (
    <div className="manual_mobile user-manual" style={{ padding: 0 }}>
      <Button
        sx={{
          borderRadius: "50%",
          minWidth: 0,
          padding: "2px",
          position: "absolute",
        }}
        style={{
          background: "white",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
        onClick={handleClose}
        disabled={coverOpen}
      >
        { !coverOpen && <IoCloseOutline className="icon_close" size={20} color="black" />}
        { coverOpen && <FaSpinner className="animate-spin" size={20}/>}
      </Button>
      <h3>
        <Trans i18nKey="user_manual.title_1"></Trans>
      </h3>
      <div className="row" style={{ width: "100%", padding: "0 14px", paddingTop: 10 }}>
        <div className="column cell" style={{ padding: 0 }}>
          <MyIcons.Icon1 />
          <p style={{ fontWeight: 300 }}>
            <Trans i18nKey="user_manual.action_1"></Trans>
          </p>
        </div>
        <div className="column cell" style={{ padding: 0 }}>
          <MyIcons.Icon2 />
          <p style={{ fontWeight: 300 }}>
            <Trans i18nKey="user_manual.action_2"></Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserManual;
