import { useContext } from "react";
import AppContext from "../../../shared/contexts/AppContext";

export default function SwitchLanguage({className}) {
  const { isEnglish, setIsEnglish } = useContext(AppContext);

  const onClick = () => {
    setIsEnglish(!isEnglish);
  };

  return (
    <div className={`action-group ${className}`}>
      <div className={`action-btn alone relative`} >
        <div

          className={isEnglish ? "active" : ""}
          style={{
            width: 25,
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <p
            onClick={() => onClick()}
            style={{
              color: isEnglish ? "#fff" : "#e31b53",
              fontFamily: "Inter",
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "24px",
              userSelect: "none",
              msUserSelect: "none"
            }}
          >
            {isEnglish ? "EN" : "VI"}
          </p>
        </div>
      </div>
    </div>
  );
}
