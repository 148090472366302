import { useContext, useEffect } from 'react';
import './MainButtons.scss';
import AppContext from '../../shared/contexts/AppContext';
import { t } from 'i18next';
import { AREA_NODE_ID, MEDIA_NODE_ID, OVERVIEW_NODE_ID } from '../../shared/constants/const';

const MainButtons = ({ }) => {
  const { mediaOpen, setMediaOpen, currentNode, setCurrentNode } = useContext(AppContext);

  useEffect(() => console.log(`Effect: ${currentNode}`), [currentNode]);
  const onOverviewClick = () => {
    if (window.panoHelper && window.panoHelper.pano.getCurrentNode() != OVERVIEW_NODE_ID) {
      window.panoHelper.loadbyNode(OVERVIEW_NODE_ID);
    } else {
      // window.panoHelper?.pano.moveTo(21, -2, 140, 0); // Default value
    }

    if (currentNode != OVERVIEW_NODE_ID) {
      setCurrentNode(OVERVIEW_NODE_ID);
    }
  }

  const onAreaClick = () => {
    setMediaOpen(!mediaOpen);
    if (window.panoHelper && window.panoHelper.pano.getCurrentNode() != AREA_NODE_ID) {
      window.panoHelper.loadbyNode(AREA_NODE_ID);
    } else {
      // window.panoHelper?.pano.moveTo(8, -8, 140, 0); // Default value
    }

    if (currentNode != AREA_NODE_ID) {
      setCurrentNode(AREA_NODE_ID);
    }
  }
  
  const onMediaClick = () => {
    if (window.panoHelper && window.panoHelper.pano.getCurrentNode() != MEDIA_NODE_ID) {
      window.panoHelper.loadbyNode(MEDIA_NODE_ID);
    } else {
      // window.panoHelper?.pano.moveTo(8, -8, 140, 0); // Default value
    }

    if (currentNode != MEDIA_NODE_ID) {
      setCurrentNode(MEDIA_NODE_ID);
    }
  }

  return (<div className={`main-buttons`}>
    <div className={`main-button ${currentNode == OVERVIEW_NODE_ID && 'active'}`} onClick={onOverviewClick}>{t('overview')}</div>
    <div className='separator'></div>
    {/* <div className={`main-button ${currentNode == AREA_NODE_ID && 'active'}`} onClick={onAreaClick}>{t('areas')}</div> */}
    <div className={`main-button ${!!mediaOpen && 'active'}`} onClick={onAreaClick}>{t('areas')}</div>
    <div className='separator'></div>
    <div className={`main-button ${currentNode == MEDIA_NODE_ID && 'active'}`} onClick={onMediaClick}>{t('media')}</div>
  </div>);
}

export default MainButtons;