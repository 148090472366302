import { OVERVIEW_NODE_ID } from "../../shared/constants/const";

// /* eslint-disable*/
function parseParams(paramsString, params) {
	paramsString = paramsString.substring(1);
	var firstSeparatorPos = paramsString.indexOf(",");
	if (firstSeparatorPos !== -1) {
		params.startNode = paramsString.slice(0, firstSeparatorPos);
		var viewingParamsString = paramsString.slice(firstSeparatorPos + 1);
		var viewingParams = viewingParamsString.split(",");
		if (viewingParams.length >= 3) {
			var startView = {};
			startView["pan"] = viewingParams[0];
			startView["tilt"] = viewingParams[1];
			startView["fov"] = viewingParams[2];
			if (viewingParams.length >= 4) {
				startView["projection"] = viewingParams[3];
			}
			params.startView = startView;
		}
	} else {
		params.startNode = paramsString;
		params.startView = "";
	}
}
var params = {};
parseParams(document.location.hash, params);
var startNode = params.startNode;
var startView = params.startView;



var panoHelper= (function ($) {
	var module = {};
	var pano = {};
	module.rootNode=OVERVIEW_NODE_ID;
	module.currentXml= "pano.xml";
	module.currentNode= OVERVIEW_NODE_ID;
	module.init = function () {
		// create the panorama player with the container
		pano = new window.pano2vrPlayer("container");
		pano.startNode = startNode;
		pano.startView = startView;
		pano.setQueryParameter("ts=96212179")

		pano.addListener('changenode', function(nodeId) {
			window.changenodepano && window.changenodepano(nodeId);
		});

		pano.addListener('mousedown', function(event) {
			var hotspotId = pano.getHotspotNodeId();
			console.log("Other hotspot clicked:", hotspotId);
		});

		if (("onhashchange" in window) && (!(/MSIE (\d+\.\d+);/.test(navigator.userAgent)))) {
			window.onhashchange = function () {
				parseParams(window.location.hash, params);
				pano.openNext('{' + params.startNode + '}', params.startView);
			}
		} else {
			var lastHash = window.location.hash;
			window.setInterval(function () {
				if (window.location.hash !== lastHash) {
					lastHash = window.location.hash;
					parseParams(window.location.hash, params);
					pano.openNext('{' + params.startNode + '}', params.startView);
				}
			}, 100);
		}

		// add the skin object
		new window.pano2vrSkin(pano);
		// create the panorama player with the container
		if (window.navigator.userAgent.match(/Safari/i)) {
			// fix for white borders, rotation on iPhone
			function iosHfix(e) {
				window.scrollTo(0, 1);
				var container=document.getElementById("container");
				var oh=container.offsetHeight;
				document.documentElement.style.setProperty('height', '100vh');
				if (oh!==container.offsetHeight) {
					container.style.setProperty('height',"100%");
				} else {
					container.style.setProperty('height',window.innerHeight+"px");
				}
				window.scrollTo(0, 0);
				pano.setViewerSize(container.offsetWidth, container.offsetHeight);
			};
			setTimeout(iosHfix,0);
			setTimeout(iosHfix,100);
			window.addEventListener("resize", function() {
				setTimeout(iosHfix,0);
				// hide toolbar on iPad happens with a delay
				setTimeout(iosHfix,500);
				setTimeout(iosHfix,1000);
				setTimeout(iosHfix,2000);
			});
		}

		module.pano = pano;
	}
	module.loadPano = function(xml="",callback){
		// load the configuration
		if(xml===""){
			xml= panoHelper.currentXml;
		}
		pano.readConfigUrlAsync("/pano/"+xml,callback);
		window.panoLoadDone = true;
	}
	module.loadbyNode = function(node_id,target,callback){
		//if(node_id != panoHelper.currentNode){
			if(target){
				pano.openNext('{'+node_id+'}',target);
			}
			else{
				pano.openNext('{'+node_id+'}');
			}
			
			panoHelper.currentNode=node_id;
		//}
	}
	module.getNodeInfo=function(){
		var id = pano.getCurrentNode();
		return pano.getNodeUserdata(id);
	}

	module.zoomIn = function(){
		pano.setZoom(1.1); // Zoom in khi cuộn lên
	}
	return module;
  })(this);

export default panoHelper;