const EN = {
  overview: 'Overview',
  areas: 'Zone',
  media: 'Facility',
  hotlineCopied: 'Hotline is coppied to clipboard',
  commingSoon: 'Comming soon!',
  photoCaption: "Photo of project's media",
  photoDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  siteTitle: 'Overview of Anara Binh Tien project',
  siteDescription: 'Explore Anara Binh Tien with full view',
  fullscreenNotSupported: 'Fullscreen mode is not support on this browser',
  user_manual: {
    title_1: "On the touch screen",
    action_1: "Zoom in/zoom out",
    action_2: "Move",
    title_2: "Mouse operations",
    action_3: "Click and drag the mouse to move.",
    action_4: "Scroll mouse to zoom in, zoom out",
    title_3: "Keyboard operations",
    action_5: "Use arrow keys to move",
    title_4: "On-screen operations",
    action_6: "Click to move to other locations",
  },
  anchor_point: {
    v43: {
      name: "Overview 2",
      numCount: "This is panorama 2",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v44: {
      name: "Overview 1",
      numCount: "This is panorama 1",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v45: {
      name: "Overview 3",
      numCount: "This is panorama 3",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v46: {
      name: "Zone B",
      numCount: "This is Zone B",
      shortDes: "Lorem Ipsum is simply dummy text of the printing and",
    },
    v47: {
      name: "Lagoon Sports House",
      numCount: "This is the Sports center",
      shortDes:
        "Sport center Lorem Ipsum is simply dummy text of the printing and",
    },
    v48: {
      name: "Elite Beach Club",
      numCount: "This is Clubhouse",
      shortDes:
        "Clubhouse Lorem Ipsum is simply dummy text of the printing and",
    },
    v49: {
      name: "Tropical Park",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v50: {
      name: "Zone A",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v51: {
      name: "SOLARI by ANARA",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v52: {
      name: "Zone C",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v53: {
      name: "Zone D",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v54: {
      name: "Zone E",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v55: {
      name: "5-star Golf Hotel",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v56: {
      name: "Hillside Villa Area",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v57: {
      name: "Swiss-Belvillas Solavita",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v58: {
      name: "Wellness Retreat Center",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v59: {
      name: "Beachfront Tourism Service Area",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v60: {
      name: "Lighthouse Restaurant",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v61: {
      name: "Ca Tien Cape Villas Area",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v62: {
      name: "6-star Rock Mountain Hotel & Resort",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v63: {
      name: "5-star Gateway Hotel",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
    v64: {
      name: "Zone G",
      divisionName: "Coming soon",
      numCount: "This is Garden",
      shortDes: "Garden Lorem Ipsum is simply dummy text of the printing and",
    },
  },
  mediaPhoto: {
    overview1: {
      caption: 'Overview 1',
    },
    overview2: {
      caption: 'Overview 2',
    },
    overview3: {
      caption: 'Overview 3',
    },
    overview4: {
      caption: 'Overview 4',
    },
    area1: {
      caption: 'Clubhouse',
    },
    area2: {
      caption: 'Garden',
    },
    area3: {
      caption: 'Sportcenter',
    },
    area4: {
      caption: 'B7 Garden',
    },
    area5: {
      caption: 'Clubhouse',
    },
    area6: {
      caption: 'Stadium',
    },
    zone1: {
      caption: 'Zone B1',
    },
    zone2: {
      caption: 'Zone B2',
    },
    zone3: {
      caption: 'Zone B3',
    },
    zoneB: {
      caption: 'Zone B',
    },
  },
};

export default EN;