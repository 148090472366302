import { useEffect, useState } from "react";
import { ContactIcon } from "../icons";
import { FaRegCopy } from "react-icons/fa";
import { copyTextToClipboard } from "../../../shared/utils/common-utils";
import { PHONE_NUMBER } from "../../../shared/constants/const";
import { Alert } from "@mui/material";
import { motion } from "framer-motion";
import { t } from 'i18next';

let alertTimeout = null;
const ContactButtons = () => {
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(100);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    let interval = null;
    if (open) {
      setWidth(100);
      interval = window.setInterval(() => {
        // 2000ms = 100%
        // 10ms = 100 * 10 / 2000 = 
        setWidth(prevWidth => {
          const newWidth = prevWidth - (1 / 2);
          return newWidth > 0 ? newWidth : 0;
        });
      }, 10);
    }

    return () => {
      window.clearInterval(interval);
    }
  }, [open]);

  useEffect(() => {
    if (width <= 0 && open) {
      setOpen(false);
    }
  }, [width]);

  const onCopy = () => {
    copyTextToClipboard(PHONE_NUMBER);
    setAlertOpen(true);

    if (alertTimeout) {
      clearTimeout(alertTimeout);
    }

    alertTimeout = setTimeout(() => {
      setAlertOpen(false);
    }, 2000);
  }

  return (
    <div className='relative'>
      <div className='action-btn alone top-btn' onClick={() => setOpen(!open)}>
        <ContactIcon />
      </div>

      {open &&
        <div className='absolute top-0 -left-[6px] -translate-x-full h-[40px] z-2 bg-[white] whitespace-nowrap text-gray-500 py-2 px-4 rounded-md'>
          Hotline: {PHONE_NUMBER}&nbsp;
          <span className="p-1 cursor-pointer primary-color-hover" onClick={onCopy}>< FaRegCopy className="inline" /></span>
          <div className='absolute bottom-0 right-0 h-[3px] bg-primary' style={{ width: `${width}%` }}></div>
        </div>
      }

      {alertOpen &&
        <motion.div className="fixed bottom-[80px] md:bottom-10 right-[6px] md:right-20 z-[1000]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <Alert severity="success">{t('hotlineCopied')}</Alert>
        </motion.div>}
    </div>
  );
}

export default ContactButtons;