import React, { useEffect }from 'react';
import panoHelper from './pano.js';


const MainViewPano = () => {
  useEffect(() => {
    if (!window.panoHelper) {
      panoHelper.init();
      panoHelper.loadPano("");
      window.panoHelper = panoHelper;
    }
  }, []);
  
  return (
    <div 
      id="container" 
      style={{
        width: "100vw", 
        height: "100vh", 
        overflow: "hidden"
      }}
    />
  );
}

export default React.memo(MainViewPano);