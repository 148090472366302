import { createContext, useEffect, useRef, useState } from 'react';
import UserManual from '../../components/UserManual/UserManual';
import { OVERVIEW_NODE_ID } from '../constants/const';
import { useTranslation } from 'react-i18next';

const AppContext = createContext({ loading: false });

export const AppContextProvider = ({ children }) => {
  const [manualOpen, setManualOpen] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [soundOn, setSoundOn] = useState(false);
  const [currentNode, setCurrentNode] = useState(OVERVIEW_NODE_ID);
  const [photo2DViewingUrl, setPhoto2DviewingUrl] = useState()
  const { i18n } = useTranslation();

  const getLanguage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('lang')?.toLocaleLowerCase();
  }

  const [isEnglish, setIsEnglish] = useState(getLanguage() == 'en');

  useEffect(() => {
    setTimeout(() => {
      setManualOpen(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (isEnglish != (i18n.language == 'en')) {
      const param = isEnglish ? "en" : "vi";
      i18n.changeLanguage(param);
      const params = new URLSearchParams(window.location.search);
      params.set('lang', param);
      window.history.pushState({}, '', `${window.location.pathname}?${params}`);
    }
  }, [isEnglish]);

  return (
    <AppContext.Provider
      value={{
        manualOpen, setManualOpen,
        mediaOpen, setMediaOpen,
        soundOn, setSoundOn,
        isEnglish, setIsEnglish,
        currentNode, setCurrentNode,
        photo2DViewingUrl, setPhoto2DviewingUrl,
      }}>
      {children}
      <UserManual />
    </AppContext.Provider>
  );
};

export default AppContext;